*, ::after, ::before {
  box-sizing: border-box;
}  
  .editorContainer {
    border: 1px solid #ddd;
    padding: 10px;
    min-height: 200px;
  }
  .editor-wrapper {
    width: 100%;
    border: 1px solid black;
    background: #fff;
    padding: 1rem;
  }
  
  .editor-container {
    width: 100%;
    border: 1px solid green;
    padding: 1rem;
  }
  
  .toolbar-grid {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1fr 1fr;
  }
  
  button {
    margin-right: 8px;
    font-size: 1rem;
    padding: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
  }
  
  .superFancyBlockquote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    border-left: 2px solid #999;
    padding-left: 10px;
  }
  
  .codeBlock {
    font-family: fira-code, monospace;
    font-size: inherit;
    background: #ffeff0;
    font-style: italic;
    word-wrap: normal;
    word-wrap: break-word;
    box-decoration-break: slice;
    padding: 0.3rem 2rem;
    border-radius: 0.2rem;
  }
  
  .leftAlign {
    text-align: left;
  }
  .rightAlign {
    text-align: right;
  }
  .centerAlign {
    text-align: center;
  }
  .justifyAlign {
    text-align: justify;
  }
  .flex {
    display: flex;
  }
  .items-center {
    align-items: center;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .flex-1 {
    flex: 1 1;
  }
  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .button {
    background: #01f0d0;
    border-radius: 9999px;
    margin: 0;
  }
  